import Vue from 'vue';
import '@/config/plugins';
import '@/config/styles';
import '@/config/components';
import { onMounted, ref } from '@vue/composition-api';
import vuetify from '@/config/plugins/vuetify';
import { useHeader } from '@/app/use/useLayouts';

export default new Vue({
  el: '#default',
  name: 'Default',
  delimiters: ['[{', '}]'],
  vuetify,
  setup() {
    const bar1 = ref(false);
    const bar2 = ref(false);
    const bar3 = ref(false);
    onMounted(() => {
      setTimeout(() => {
        const carga = document.getElementById('carga')!;
        carga.classList.add('desaparecer');
      }, 1000);
      setTimeout(() => {
        AOS.init();
      }, 1500);
      setTimeout(() => {
        bar1.value = true;
      }, 500);
      setTimeout(() => {
        bar2.value = true;
      }, 1000);
      setTimeout(() => {
        bar3.value = true;
      }, 1500);
    });

    return {
      ...useHeader(),
      bar1,
      bar2,
      bar3,
    };
  },
});
